<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table">
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '人员列表'}
        ],
        table: {
          api: vm.$api_hw.realTimeSupervision_getPersonByIdList,
          query: {
            gridId: this.$route.query.gridId,
            personIdList: this.$route.query.personIdList,
          },
          // 是否显示分页
          // hidePagination: true,
          // 表格查询的条件组件
          searchData: [
            // {
            //   type: 'input',
            //   model: 'personIdList',
            //   placeholder: '姓名'
            // }
            ],
          columns: [
            {
              title: '序号',
              width: '50px',
              $index: 'index',
            }, {
              title: '姓名',
              width: '80px',
              key: 'name',
            }, {
              title: '设备号',
              key: 'deviceId',
              width: '180px',
            }, {
              title: '人员类型',
              width: '120px',
              render(h, ctx) {
                return h("span", vm.onPersonType(ctx.row.personType) || "- -");
              }
            },{
              title: '排班网格',
              key: 'gridName',
            },{
              title: '现在网格',
              key: 'currentGridName',
            }, {
              title: '在岗状态',
              key: 'personDutyDesc',
            },
            ]
        },
      }
    },
    methods: {
      //人员类型
    onPersonType(res){
        switch (res){
            case "0":
                return '项目经理'
            case "1":
                return '项目副经理'
            case "2":
                return '片区负责人'
            case "3":
                return '车队长'
            case "4":
                return '小组长'
            case "5":
                return '安全生产管理员'
            case "6":
                return '一线保洁人员'  
            case "7":
                return '垃圾收集工'  
            case "8":
                return '设备操作工'
            case "9":
                return '扫路车司机' 
            case "10":
                return '清洗车司机'
            case "11":
                return '垃圾收集车司机'
            case "12":
                return '钩臂车司机'
            case "13":
                return '平板车司机'
            case "14":
                return '公厕保洁员'   
            case "15":
                return '水电工' 
            case "16":
                return '其它'    
        }
    },
    },
    async mounted () {
      // await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
    margin-bottom: 15px
    padding-left: 15px
    color: #222
    font-size: 16px
  /deep/.el-button--success 
    color: #FFFFFF
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)

  .order-img
    position: relative
    width: calc((100% - 2 * 13px) / 3)
    height: 138px
    display: inline-block
    margin-right: 13px
    margin-bottom: 14px
    border-radius: 4px
  .close-icon
    position: absolute
    top: -5px
    right: -8px
    font-size: 14px
    cursor: pointer

</style>
